import React from 'react'
import dynamic from 'next/dynamic'
import classnames from 'classnames/bind'
import css from './styles.module.scss'

const cx = classnames.bind(css)

// object with dynamic imports but with keys from switch
const dynamicComponents = {
  'slices.stories-grid': dynamic(
    () => import('components/slices/StoriesGrid'),
    { ssr: false }
  ),
  'slices.brands': dynamic(() => import('components/slices/Brands'), {
    ssr: false,
  }),
  'slices.related-showcases': dynamic(
    () => import('components/slices/RelatedShowcases'),
    { ssr: false }
  ),
  'slices.brands-with-intro': dynamic(
    () => import('components/slices/BrandsWithIntro'),
    { ssr: false }
  ),
  'slices.top-features': dynamic(
    () => import('components/slices/TopFeatures'),
    { ssr: false }
  ),
  'slices.stepper': dynamic(() => import('components/slices/Stepper'), {
    ssr: false,
  }),
  'slices.integration-cards-grid': dynamic(
    () => import('components/slices/IntegrationsCardsWithIntro'),
    { ssr: false }
  ),
  'slices.text-with-key-numbers': dynamic(
    () => import('components/slices/TextWithKeyNumbers'),
    { ssr: false }
  ),
  'slices.image-gallery': dynamic(
    () => import('components/slices/ImageGallery'),
    { ssr: false }
  ),
  'slices.interview': dynamic(() => import('components/slices/Interview'), {
    ssr: false,
  }),
  'slices.quote': dynamic(() => import('components/slices/Quote'), {
    ssr: false,
  }),
  'slices.full-width-quote': dynamic(
    () => import('components/slices/FullWidthQuote'),
    { ssr: false }
  ),
  'slices.text-slice': dynamic(() => import('components/slices/TextSlice'), {
    ssr: false,
  }),
  'slices.text-next-to-image': dynamic(
    () => import('components/slices/TextNextToImage'),
    { ssr: false }
  ),
  'slices.stacking-cards': dynamic(
    () => import('components/slices/StackingCards'),
    { ssr: false }
  ),
  'slices.case-study-card': dynamic(
    () => import('components/slices/FullWidthCardPush'),
    { ssr: false }
  ),
  'slices.newsletter-banner': dynamic(
    () => import('components/slices/NewsletterBanner'),
    { ssr: false }
  ),
  'slices.text-with-cards': dynamic(
    () => import('components/slices/TextWithCards'),
    { ssr: false }
  ),
  'slices.text-next-to-parallelogram-image': dynamic(
    () => import('components/slices/TextNextToParallelogramImage'),
    { ssr: false }
  ),
  'slices.text-surrounded-by-two-images': dynamic(
    () => import('components/slices/TextSurroundedByTwoImages'),
    { ssr: false }
  ),
  'slices.reviews-slider': dynamic(
    () => import('src/components/slices/CardsSlider'),
    { ssr: false }
  ),
  'slices.dark-reviews-slider': dynamic(
    () => import('src/components/slices/CardsSliderDark'),
    { ssr: false }
  ),
  'slices.event-slider': dynamic(
    () => import('src/components/slices/CardsSlider'),
    { ssr: false }
  ),
  'slices.contributors-slice': dynamic(
    () => import('components/slices/Contributors'),
    { ssr: false }
  ),
  'slices.features-slice': dynamic(
    () => import('components/slices/FeaturesSlice'),
    { ssr: false }
  ),
  'slices.large-features-slice': dynamic(
    () => import('components/slices/LargeFeaturesSlice'),
    { ssr: false }
  ),
  'slices.text-next-to-big-image': dynamic(
    () => import('components/slices/TextNextToBigImage'),
    { ssr: false }
  ),
  'slices.related-case-studies': dynamic(
    () => import('src/components/slices/RelatedCaseStudies'),
    { ssr: false }
  ),
  'slices.related-blog-posts': dynamic(
    () => import('components/slices/RelatedArticles'),
    { ssr: false }
  ),
  'slices.related-tutorials': dynamic(
    () => import('components/slices/RelatedTutorials'),
    { ssr: false }
  ),
  'slices.cta-banner': dynamic(() => import('components/slices/CTABanner'), {
    ssr: false,
  }),
  'slices.universal-rich-text': dynamic(
    () => import('components/slices/UniversalRichText'),
    { ssr: false }
  ),
  'slices.embed-form': dynamic(() => import('components/slices/EmbedForm'), {
    ssr: false,
  }),
  'slices.embed-tweets': dynamic(
    () => import('components/slices/EmbedTweets'),
    { ssr: false }
  ),
  'slices.embed-form-next-to-cards': dynamic(
    () => import('components/slices/EmbedFormNextToCards'),
    { ssr: false }
  ),
  'slices.faq': dynamic(
    () => import('components/slices/FrequentlyAskedQuestions'),
    { ssr: false }
  ),
  'slices.plans-grid': dynamic(() => import('components/slices/PlansGrid'), {
    ssr: false,
  }),
  'slices.intro': dynamic(() => import('components/slices/Intro'), {
    ssr: false,
  }),
  'slices.getting-started-grid': dynamic(
    () => import('components/slices/GettingStartedGrid'),
    { ssr: false }
  ),
  'slices.news-list': dynamic(() => import('components/slices/NewsList'), {
    ssr: false,
  }),
  'slices.media-resources-list': dynamic(
    () => import('components/slices/MediaResourcesList'),
    { ssr: false }
  ),
  'slices.content-cards-list': dynamic(
    () => import('components/slices/ContentCardsList'),
    { ssr: false }
  ),
  'slices.large-image': dynamic(() => import('components/slices/LargeImage'), {
    ssr: false,
  }),
  'slices.large-video': dynamic(() => import('components/slices/LargeVideo'), {
    ssr: false,
  }),
  'slices.content-videos-list': dynamic(
    () => import('components/slices/ContentVideosList'),
    { ssr: false }
  ),
  'slices.featured-video': dynamic(
    () => import('components/slices/FeaturedVideo'),
    { ssr: false }
  ),
  'slices.changelogs-list': dynamic(
    () => import('components/slices/ChangelogsList'),
    { ssr: false }
  ),
  'slices.changelogs-timeline': dynamic(
    () => import('components/slices/ChangelogsTimeline'),
    { ssr: false }
  ),
  'slices.info-cta-grid': dynamic(
    () => import('src/components/slices/InfoCtaGrid'),
    { ssr: false }
  ),
  'slices.plan-cards': dynamic(
    () => import('src/components/slices/PlanCards'),
    { ssr: false }
  ),
  'slices.large-plan-card': dynamic(
    () => import('src/components/slices/LargePlanCard'),
    { ssr: false }
  ),
  'slices.themed-cards': dynamic(
    () => import('src/components/slices/ThemedCards'),
    { ssr: false }
  ),
  'slices.plan-type-selector': dynamic(
    () => import('src/components/slices/PlanTypeSelector'),
    { ssr: false }
  ),
  'slices.resource-cards-list': dynamic(
    () => import('src/components/slices/ResourceCards'),
    { ssr: false }
  ),
  'slices.resource-links': dynamic(
    () => import('src/components/slices/ResourceLinks'),
    { ssr: false }
  ),
  'slices.socials-grid': dynamic(
    () => import('src/components/slices/SocialsGrid'),
    { ssr: false }
  ),
  'slices.editor-s-picks': dynamic(
    () => import('src/components/slices/EditorsPicks'),
    { ssr: false }
  ),
  'slices.text-with-image-and-gradient': dynamic(
    () => import('src/components/slices/TextNextToImageWithGradient'),
    { ssr: false }
  ),
  'slices.related-posts': dynamic(
    () => import('src/components/slices/RelatedPosts'),
    { ssr: false }
  ),
  'slices.company-stat-list': dynamic(
    () => import('components/slices/CompanyStatList'),
    { ssr: false }
  ),
  'slices.features-card': dynamic(
    () => import('components/slices/FeaturesCard'),
    { ssr: false }
  ),
  'slices.tech-stack-icon-list': dynamic(
    () => import('components/slices/TechStackIconList'),
    { ssr: false }
  ),
  'slices.testimonies': dynamic(() => import('components/slices/Testimonies'), {
    ssr: false,
  }),
  'slices.perk-lists': dynamic(() => import('components/slices/PerkLists'), {
    ssr: false,
  }),
  'slices.image-slider': dynamic(
    () => import('components/slices/ImageSlider'),
    { ssr: false }
  ),
  'slices.team-slice': dynamic(() => import('components/slices/TeamSlice'), {
    ssr: false,
  }),
  'slices.open-positions-banner': dynamic(
    () => import('components/slices/OpenPostionsBanner'),
    { ssr: false }
  ),
  'slices.new-intro': dynamic(() => import('components/slices/NewIntro'), {
    ssr: false,
  }),
  'slices.headline-art': dynamic(
    () => import('components/slices/HeadlineArt'),
    { ssr: false }
  ),
  'slices.simple-text-next-to-image': dynamic(
    () => import('components/slices/SimpleTextNextToImage'),
    { ssr: false }
  ),
  'slices.new-cta': dynamic(() => import('components/slices/NewCta'), {
    ssr: false,
  }),
  'slices.single-animation': dynamic(
    () => import('components/slices/SingleAnimation'),
    { ssr: false }
  ),
  'slices.toggle-animations': dynamic(
    () => import('components/slices/ToggleAnimations'),
    { ssr: false }
  ),
  'slices.section-with-image': dynamic(
    () => import('components/slices/SectionWithImage'),
    { ssr: false }
  ),
  'slices.spacer': dynamic(() => import('src/components/slices/Spacer'), {
    ssr: false,
  }),
  'slices.icon-cards': dynamic(
    () => import('src/components/slices/IconCards'),
    {
      ssr: false,
    }
  ),
  'shared.home-hero': dynamic(() => import('src/components/shared/HomeHero'), {
    ssr: false,
  }),

  'shared.white-hero': dynamic(
    () => import('src/components/shared/WhiteHero'),
    {
      ssr: false,
    }
  ),
  'shared.features-hero': dynamic(
    () => import('src/components/shared/FeaturesHero'),
    {
      ssr: false,
    }
  ),
  'shared.community-hero': dynamic(
    () => import('src/components/shared/CommunityWhiteHero'),
    {
      ssr: false,
    }
  ),
  'shared.use-case-hero': dynamic(
    () => import('src/components/shared/UseCaseHero'),
    {
      ssr: false,
    }
  ),
  'slices.hero-dark': dynamic(
    () => import('src/components/pages/launchWeek/DarkHero'),
    {
      ssr: false,
    }
  ),
  'slices.image-slider': dynamic(
    () => import('src/components/slices/ImageSlider'),
    {
      ssr: false,
    }
  ),
  'slices.event-section': dynamic(
    () => import('src/components/pages/launchWeek/ScheduleSection'),
    {
      ssr: false,
    }
  ),
  'slices.community-section': dynamic(
    () => import('src/components/pages/launchWeek/CommunitySection'),
    {
      ssr: false,
    }
  ),
  'slices.awards-launch-section': dynamic(
    () => import('src/components/pages/launchWeek/AwardsLaunchSection'),
    {
      ssr: false,
    }
  ),
  'slices.dark-cli': dynamic(() => import('src/components/slices/DarkCli'), {
    ssr: false,
  }),
  'slices.automated-related-blog-post': dynamic(
    () => import('src/components/slices/AutomatedRelatedBlogPost'),
    { ssr: false }
  ),
  'slices.get-demo-layout': dynamic(
    () => import('src/components/slices/GetDemoLayout'),
    { ssr: false }
  ),
  'slices.contact-header': dynamic(
    () => import('src/components/slices/ContactHeader'),
    { ssr: false }
  ),
  'slices.contact-form-section': dynamic(
    () => import('src/components/slices/ContactFormSection'),
    { ssr: false }
  ),
  'slices.side-hero-with-image': dynamic(
    () => import('src/components/slices/SideHeroWithImage'),
    { ssr: false }
  ),
  'slices.two-columns-benefits': dynamic(
    () => import('src/components/slices/TwoColumnsBenefits'),
    { ssr: false }
  ),
  'slices.features-grid': dynamic(
    () => import('src/components/slices/FeaturesGrid'),
    { ssr: false }
  ),
  'slices.capabilities-dynamic-cards': dynamic(
    () => import('src/components/slices/CapabilitiesDynamicCards'),
    { ssr: false }
  ),
  'slices.twitter-feed': dynamic(
    () => import('src/components/slices/TwitterFeed'),
    { ssr: false }
  ),
  'slices.dark-cta-banner': dynamic(
    () => import('src/components/slices/DarkCTABanner'),
    { ssr: false }
  ),
}
const getSliceComponent = ({ __component, ...rest }, index) => {
  const SliceComponent = dynamicComponents[__component]

  return SliceComponent ? (
    <SliceComponent key={`index${index}`} className={css.slice} {...rest} />
  ) : null
}

const SliceManager = ({ className, slices }) => {
  return (
    <div className={cx(className, slices?.length === 0 ? css.empty : '')}>
      {slices?.map(getSliceComponent)}
    </div>
  )
}

SliceManager.defaultProps = {
  slices: [],
}

export default SliceManager
